import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom'; // Importamos useLocation
import api from 'utils/api';
import { isValidToken, setSession } from 'utils/jwt';
import { ROUTES } from './routes';

interface PrivateRouteProps {
  children: React.ReactNode;
  [key: string]: any; // Para manejar otras props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const state = useSelector((state: any) => state.user);
  const location = useLocation(); // Usamos useLocation para obtener la ubicación actual

  useEffect(() => {
    const getUserInformation = async () => {
      if (!tokenLocal) return;
      return await api('/auth/my-account', 'POST', {}, false, {
        status: true,
        access_token: tokenLocal,
      });
    };

    if (Object.keys(state).length === 0 && hasToken) {
      getUserInformation().then((res) => {
        dispatch({ type: 'loadAccount', payload: res });
      });
    } else if (!hasToken) {
      setSession('');
    }
  }, [hasToken, dispatch, tokenLocal, state]);

  // Si no hay token, redirigimos al login
  if (!hasToken) {
    return (
      <Navigate
        to={ROUTES.LOGIN}
        state={{ from: location }} // Opcional: guardamos la ubicación actual
        replace
      />
    );
  }

  // Si hay token, renderizamos el componente
  return <>{children}</>;
};

export default PrivateRoute;
