import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'user',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase('login', (state, action) => {
        return { ...state, isAuth: true };
      })
      .addCase('loadAccount', (state, action) => {
        return { ...state, info: action.payload };
      })
      .addCase('logout', (state, action) => {
        return { ...state, isAuth: false };
      });
  },
});

export const { login, loadAccount, logout } = usersSlice.actions;

export default usersSlice.reducer;
