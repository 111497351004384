import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import MyRouter from 'routers/index';
import store from 'store';

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <ReduxProvider store={store}>
        <MyRouter />
      </ReduxProvider>
    </div>
  );
}

export default App;
