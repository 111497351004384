import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'; // Importamos useLocation
import { isValidToken } from 'utils/jwt';

interface PublicOnlyRouteProps {
  children: React.ReactNode;
  [key: string]: any; // Para manejar otras props
}

const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = ({
  children,
  ...rest
}) => {
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const redirect = localStorage.getItem('redirect');
  const location = useLocation(); // Usamos useLocation para obtener la ubicación actual

  // Si hay token, redirigimos al usuario
  if (hasToken) {
    return (
      <Navigate
        to={redirect || '/'} // Redirigimos a la ruta guardada o a la raíz
        replace
        state={{ from: location }} // Opcional: guardamos la ubicación actual
      />
    );
  }

  // Si no hay token, renderizamos el componente
  return <>{children}</>;
};

export default PublicOnlyRoute;
