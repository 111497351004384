import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const location = useLocation(); // Usamos useLocation para detectar cambios en la ruta

  useEffect(() => {
    // Desplazar la página al principio cada vez que cambia la ruta
    window.scrollTo(0, 0);
  }, [location.pathname]); // Escuchamos cambios en la ruta actual

  return null; // Este componente no renderiza nada
};

export default ScrollToTop;