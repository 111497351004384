import loadable from '@loadable/component';
import React, { FC, Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes as Switch,
  Navigate,
} from 'react-router-dom';
import { Page } from './types';
import { isValidToken, setSession } from 'utils/jwt';
import { useSelector, useDispatch } from 'react-redux';
import api from 'utils/api';
import { AnimatePresence } from 'framer-motion';
import { Toaster } from 'react-hot-toast';

import { ROUTES } from './routes';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';

import ScrollToTop from './ScrollToTop';

import { FallbackComponent } from 'components/LoadingScreen/LoadingScreen';
const loadableOptions = { fallback: <FallbackComponent /> };

const PageHome = loadable(
  () => import('containers/PageHome/PageHome'),
  loadableOptions
);
const SignUpPage = loadable(
  () => import('pages/Auth/SignUpPage/SignUpV2Page'),
  loadableOptions
);
const SignUpGreetherPage = loadable(
  () => import('pages/Auth/SignUpPage/SignUpGreetherPage'),
  loadableOptions
);
const LoginPage = loadable(
  () => import('pages/Auth/LoginPage'),
  loadableOptions
);
const BookingStepOnePage = loadable(
  () => import('pages/Booking/StepOnePage/'),
  loadableOptions
);
const BookingStepTwoPage = loadable(
  () => import('pages/Booking/StepTwoPage/'),
  loadableOptions
);
const BookingStepThreePage = loadable(
  () => import('pages/Booking/StepThreePage/'),
  loadableOptions
);
const AdditionalTravelerRequirements = loadable(
  () => import('pages/Booking/AdditionalTravelerRequirements'),
  loadableOptions
);
const BookingConfirmed = loadable(
  () => import('pages/Booking/BookingConfirmedPage/BookingConfirmedPage'),
  loadableOptions
);
const BookingCheckoutPage = loadable(
  () => import('pages/Booking/CheckoutPage'),
  loadableOptions
);
const Page404 = loadable(
  () => import('containers/Page404/Page404'),
  loadableOptions
);
const FAQTravelerPage = loadable(
  () => import('pages/FAQPage/FAQTravelerPage'),
  loadableOptions
);
const FAQGreetherPage = loadable(
  () => import('pages/FAQPage/FAQGreetherPage'),
  loadableOptions
);
const PrivacyPolicy = loadable(
  () => import('pages/PrivacyPolicy/PrivacyPolicy'),
  loadableOptions
);
const ConfirmEmailPage = loadable(
  () => import('pages/Auth/ConfirmationPage/ConfirmationPage')
);
const AwaitingConfirmationPage = loadable(
  () => import('pages/Auth/ConfirmationPage/AwaitingConfirmationPage'),
  loadableOptions
);
const CancellationPolicyPage = loadable(
  () => import('pages/FAQPage/CancellationPolicyPage'),
  loadableOptions
);
const NewPasswordPage = loadable(
  () => import('pages/Auth/NewPasswordPage/NewPasswordPage'),
  loadableOptions
);
const ListingExperiencesDetailPage = loadable(
  () => import('containers/ListingDetailPage/ListingExperiencesDetailPage'),
  loadableOptions
);
const ListingStayPage = loadable(
  () => import('containers/ListingStayPage/ListingStayPage'),
  loadableOptions
);
const UserPage = loadable(
  () => import('containers/UserPage/UserPage'),
  loadableOptions
);
const MyProfilePage = loadable(
  () => import('pages/MyProfilePage/MyProfilePage'),
  loadableOptions
);
const PageForgotPassword = loadable(
  () => import('containers/PageForgotPassword/PageForgotPassword'),
  loadableOptions
);

const DefineYourJourneyPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/DefineYourJourneyV2Page'),
  loadableOptions
);

const DefineYourJourneyNextPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/StepThreePage'),
  loadableOptions
);

const DefineYourJourneyFinishPage = loadable(
  () => import('pages/Auth/DefineYourJourneyPage/ConfirmateProfile'),
  loadableOptions
);

type ExternalRedirectProps = {
  to: string;
};

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome as FC<any> },
  { path: '/#', exact: true, component: PageHome as FC<any> },
];

const Routes = () => {
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem('accessToken');
  const hasToken = isValidToken(tokenLocal);
  const state = useSelector((state: any) => state.user);

  const getUserInformation = async () => {
    if (!tokenLocal) return;
    return await api('/auth/my-account', 'POST', {}, false, {
      status: true,
      access_token: tokenLocal,
    });
  };

  if (Object.keys(state).length === 0 && hasToken) {
    getUserInformation().then((res) => {
      dispatch({ type: 'loadAccount', payload: res });
    });
  } else if (!hasToken) {
    setSession('');
  }

  const userRole = state?.info?.account?.role || undefined;

  const ExternalRedirect: FC<ExternalRedirectProps> = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<FallbackComponent />}>
        <Toaster position="top-right" reverseOrder={false} />
        <AnimatePresence>
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return <Route key={path} Component={component} path={path} />;
            })}
            <Route
              path="/login"
              element={
                <PublicOnlyRoute>
                  <LoginPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path={ROUTES.SIGNUP}
              element={
                <PublicOnlyRoute>
                  <SignUpPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path={ROUTES.SIGNUP_GREETER}
              element={
                <PublicOnlyRoute>
                  <SignUpGreetherPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path={ROUTES.AWAITINGCONFIRMATION}
              element={
                <PublicOnlyRoute>
                  <AwaitingConfirmationPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRMEMAIL}
              element={
                <PublicOnlyRoute>
                  <ConfirmEmailPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path={ROUTES.NEW_PASSWORD}
              element={
                <PublicOnlyRoute>
                  <NewPasswordPage />
                </PublicOnlyRoute>
              }
            />
            <Route path={ROUTES.FAQGREETER} element={<FAQGreetherPage />} />
            <Route path={ROUTES.FAQTRAVELER} element={<FAQTravelerPage />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route
              path={ROUTES.CANCELLATIONPOLICY}
              element={<CancellationPolicyPage />}
            />
            <Route
              path={ROUTES.BOOKINGSTEPONE}
              element={<BookingStepOnePage />}
            />
            <Route
              path={ROUTES.BOOKINGSTEPTWO}
              element={
                <PrivateRoute>
                  <BookingStepTwoPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ADDITIONAL_REQUIREMENTS}
              element={
                <PrivateRoute>
                  <AdditionalTravelerRequirements />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.BOOKINGSTEPTHREE}
              element={
                <PrivateRoute>
                  <BookingStepThreePage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CHECKOUT}
              element={
                <PrivateRoute>
                  <BookingCheckoutPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.BOOKINGCONFIRMED}
              element={
                <PrivateRoute>
                  <BookingConfirmed />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.QUESTIONNAIRE}
              element={
                <PrivateRoute>
                  <DefineYourJourneyPage userRole={userRole} />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.QUESTIONNAIRENEXT}
              element={
                <PrivateRoute>
                  <DefineYourJourneyNextPage userRole={userRole} />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.QUESTIONNAIREFINISH}
              element={
                <PrivateRoute>
                  <DefineYourJourneyFinishPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                hasToken ? <Navigate replace to="/" /> : <PageForgotPassword />
              }
            />
            <Route
              path="/my-profile"
              element={
                hasToken ? <MyProfilePage /> : <Navigate replace to="/" />
              }
            />
            <Route
              path="/p/:userId"
              element={
                hasToken ? <UserPage userRole={userRole} /> : <SignUpPage />
              }
            />
            <Route
              path="/b/:userName"
              element={
                hasToken ? <ListingExperiencesDetailPage /> : <SignUpPage />
              }
            />
            <Route
              path="/search-greeters"
              element={hasToken ? <ListingStayPage /> : <SignUpPage />}
            />
            <Route
              path="/contact"
              element={
                <ExternalRedirect to="https://www.hello.greether.com/contactgreether" />
              }
            />
            <Route path="*" element={<Page404 />} />
          </Switch>
        </AnimatePresence>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
