import { createSlice } from '@reduxjs/toolkit';
import { dateFormatedString } from 'utils/date';

export const bookSlice = createSlice({
  name: 'book',
  initialState: {
    search: {
      city: '',
      country: '',
      state: '',
      travelers: 1,
      duration: 2.5,
      price: 0,
    },
    journey: {
      date: dateFormatedString(new Date()),
      time: '10:00',
      location: '',
      languages: [],
      interests: [],
      preferences: '',
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('search', (state, action) => {
        return { ...state, search: { ...state.search, ...action.payload } };
      })
      .addCase('journey', (state, action) => {
        return { ...state, journey: { ...state.journey, ...action.payload } };
      });
  },
});

export const { search, journey } = bookSlice.actions;

export default bookSlice.reducer;
